import { Component, OnInit, Input } from '@angular/core';
import { CartComponent } from '../cart/cart.component';
import { ModalController, ToastController, AlertController } from '@ionic/angular';
import { Product } from '../../models/product.model';
import { ProductsService } from '../../services/products.service';
import { GlobalService } from '../../services/global.service';
import { ApiService } from '../../api.service';
import { accountService } from 'src/app/services/account.service';
import { Carro } from './../../models/carro.model';
import { CartService } from 'src/app/services/cart.service';


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  @Input() codigo: any;
  carro: Carro[];
  resultado = '';
  cartItems: any[] = [];
  cartProducts: Carro;
  pregunta: boolean;


  constructor(private cartService: CartService, public alertController: AlertController, public global: GlobalService, private productsService: ProductsService, public modalController: ModalController, private apiService: ApiService,
    public accountservice: accountService, public toastController: ToastController) { }

  ngOnInit() {
    console.log(this.codigo);
    this.getPedido(this.codigo);
   }

  // Go to cart page
  async gotoCartPage() {
    this.dismiss();
    const modal = await this.modalController.create({
      component: CartComponent
    });
    return await modal.present();
  }
  getPedido(codigo){
    this.accountservice.dameCarrito(codigo).then((products) => {
      this.carro = products;
      console.log('Mi carro: ');
      console.log(this.carro[0]);
    });
  }

  async repetir(documento){

    this.alertController.create({
      header: 'Añadir productos',
      subHeader: 'Confime para continuar',
      message: '¿Está seguro de estos productos a su carro?',
      buttons: [
        {
          text: 'Si',
          handler: () => {
            console.log('ha dicho que si, seguimos con el pedido');
            console.log(documento);
            let i = 0;
            var length = documento.articulos.length;
            for (i = 0; i < length; i++) {
              console.log(documento.articulos[i].codigo);
              console.log(documento.articulos[i].unid1);
              this.addtocart(documento.articulos[i], documento.articulos[i].unid1);
            };
            if(i == length){
            this.confirma();
            }
          }
        },
        {
          text: 'No',
          handler: () => {
            console.log('El pedido queda en standby');
            this.pregunta = false;
          }
        }
      ]
    }).then(res => {
      res.present();
    });

  }

  async confirma(){
    const toast = await this.toastController.create({
      message: 'Productos añadidos a tu carrito.',
      duration: 2000
    });
    toast.present();
  }

  async addtocart(articulo, i) {

  console.log(articulo);
  console.log(i);
  articulo  = await this.apiService.dameUnidades(articulo);
  this.resultado = await this.apiService.cartAdd(articulo);
  console.log(this.resultado);

  this.getCartItems(articulo.nombre);
}

async getCartItems(nombre) {
  this.cartService.cart().then((products) => {
    this.cartProducts = products;
    const object = this.cartProducts.articulos;
    console.log('Articulos carro: ' + object);
    this.global.artcart = Object.keys(object).length;
  });
}

  // Back to previous page function
  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
