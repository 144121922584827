import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, IonInfiniteScroll, ToastController } from '@ionic/angular';
import { Product } from '../../models/product.model';
import { ProductsService } from '../../services/products.service';
import { ProductDetailsComponent } from '../product-details/product-details.component';
import { CartComponent } from '../cart/cart.component';
import { ValueAccessor } from '@ionic/angular/dist/directives/control-value-accessors/value-accessor';
import { GlobalService } from '../../services/global.service';
import { ApiService } from '../../api.service';


@Component({
  selector: 'app-searchchat',
  templateUrl: './searchchat.component.html',
  styleUrls: ['./searchchat.component.scss'],
})
export class SearchchatComponent implements OnInit {

  // List of Products  
  products: Product[];
  loadedProducts: Product[];
  items: Product[];
  searchTerm = '';
  resultado = '';
  imgruta = '';
  incremento = 10;
  rutaimagen = '';
  cartItems: any[] = [];
  total: any;
  showEmptyCartMessage: boolean = false;
  loading: any;
  nophoto = '';
  count = 0;
  control: Boolean = false;
  grid: Boolean = true;
  oneColumn: Boolean = false;
  list: Boolean = false;
  // Check is product available or not
  isProductAvailable: boolean = false;
  search : string;

  constructor(public modalController: ModalController,
    private productsService: ProductsService, public global: GlobalService,
    public loadingController: LoadingController, private apiService: ApiService,
    public toastController: ToastController) { }

   ngOnInit() {

    this.products = this.global.products;
    this.items = [];
    this.nophoto = this.global.url + 'imagenes/NOFOTO.JPG';
    
    if ((this.products === undefined) || (this.products == null)) {
      this.presentLoading();
      this.getProductList();
    } else {
      // si ya se cargo cogemos direcamente los datos
      this.cargadatos();
    }

  }

  // infinity scroll
  loadData(event) {

    this.incremento = this.incremento + 10;
    event.target.complete();
  }

  onError(item) {
    console.log(item.imagen);
    item.imagen = this.nophoto;
  }
   // loading de datos
   presentLoading() {
    this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Cargando productos...'
    }).then((res) => {
      res.present();

      res.onDidDismiss().then((dis) => {
        console.log('Loading dismissed!', dis);
        this.control = true;
      });
    });

    setTimeout(() => {
      this.loadingController.dismiss().then((res) => {
      console.log('Loading dismissed!', res);
      }).catch((error) => {
      console.log('error', error);
      });
    }, 3000); 
  }

  // Añadir a favoritos
  async FavAdd(articulo) {
    console.log(articulo);

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.products.length; i++) {
      if(this.products[i].codigo === articulo){
         (this.products[i]);
        this.resultado = await this.apiService.FavAdd(this.products[i].codigo);
         (this.resultado);
        this.products[i].isWishlist = true;
        this.global.cambio = true;
      }
    }

  }

  // Quitar de favoritos
  async FavDel(articulo) {
    console.log(articulo);

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.products.length; i++) {
      if(this.products[i].codigo === articulo){
        console.log(this.products[i]);
        this.resultado = await this.apiService.FavDel(this.products[i].codigo);
        console.log(this.resultado);
        this.products[i].isWishlist = false;
        this.global.cambio = true;
      }
    }

  }

  // Get List of Products
  getProductList() {
    this.productsService.productList().then(products => {
      this.products = products;
      this.global.products = products;
       (this.products);

      // quitamos la precarga
      this.loadingController.dismiss().then((res) => {
        console.log('Loading dismissed!', res);
      }).catch((error) => {
        console.log('error', error);
      });

      this.cargadatos();
    });
  }

  // cargamos los 25 datos iniciales
  cargadatos() {
    if ((this.products === undefined) || (this.products == null)) {
    } else {
      for (let i = 0; i < 25; i++) {  // limita a los items ue necesites .
        /*console.log(this.products);
        console.log(this.products[this.count]);*/
        this.items.push(this.products[this.count]);   // EL JSON que quieres listar
        this.count++;
      }
    // this.loadingController.dismiss();
    }
  }

  // busqueda antigua
  getProducts(ev: any) {
    if (this.products == null || this.products.length === 0) {
        return;
      } else {
        if ( this.searchTerm.length >= 3 ) {
        this.items = this.products.filter(item => {
          return item.descripcion.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
        });
      } else {

        this.items = [];
        this.getProductList();

        return;
      }
    }
  }
  // Go to product details page
  async goToProductDetails(item1) {

    const modal = await this.modalController.create({
      component: ProductDetailsComponent,
      componentProps: { producto: item1 }
    });
    return await modal.present();
  }

  close(item){
    console.log(item);
    this.modalController.dismiss(item);
  }

  loadImage(variable){
    console.log(variable);
    const image = new Image();
    this.rutaimagen = variable.split('\\').pop().split('/').pop();
    this.imgruta = this.rutaimagen.split('.').slice(0, -1).join('.');
    this.rutaimagen = this.global.url + 'imagenes/' + this.imgruta.toUpperCase();
    image.src = this.rutaimagen + '.jpg';
    console.log(image.src);
    if (image.width === 0) {
      (this.rutaimagen + '.JPG');
     image.src = this.rutaimagen + '.JPG';
     if (image.width === 0) {
       return this.nophoto;
     } else {
      this.imgruta = this.rutaimagen + '.JPG';
      return this.imgruta;
     }
    }
 }
 changeQty(item, i, change: number){
  console.log(i);
  let qty = 0;
  let control = 1;
  
  setTimeout(() => {
    if (this.global.unidadMedidaMiniaturas329 == 0) {
      
      qty = item.unid2;
      control = 2;
    } else if(this.global.unidadMedidaMiniaturas329 == 1) {
      qty = item.unid1;
    } else if(this.global.unidadMedidaMiniaturas329 == 2) {
      if(item.precioSobre == 'S'){
        qty = item.unid1;
      } else {
        qty = item.unid2;
        control = 2;
      }
    }
    if (change < 0 && item.unid1 == 0 && control == 1) {
      return;
    }
    if (change < 0 && item.unid2 == 0 && control == 2) {
      return;
    }

    qty = Number(qty) + Number(change);

    console.log(qty);

    if (control === 1) {
      item.unid1 = qty;
    } else {
      item.unid2 = qty;
    }
    console.log(item.unid2);
  }, 5);
}

async addtocart(articulo, i){
  console.log(articulo);
  console.log(i);
  articulo  = await this.apiService.dameUnidades(articulo);
  this.resultado = await this.apiService.cartAdd(articulo);
  console.log(this.resultado);

  const toast = await this.toastController.create({
    message: articulo.nombre + ' añadido a tu carrito.',
    duration: 2000
  });
  toast.present();
}

  // Abrir la página de filtros
  /*async openFilterPage() {
    const modal = await this.modalController.create({
      component: FilterComponent
    });
    return await modal.present();
  }*/

  // Go to cart page function
  async gotoCartPage() {
    this.dismiss();
    const modal = await this.modalController.create({
      component: CartComponent
    });
    modal.onDidDismiss().then((data) => {
        this.getProductList();        
      });
    return await modal.present();
  }

  // Back to previous page function
  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

}
