import { Injectable } from '@angular/core';
import { Product } from '../models/product.model';
import { ApiService } from '../api.service';
import { isNull } from 'util';
import { filtros } from '../models/filtros.model';
import { parametros } from '../models/parametros.model';
import { GlobalService } from '../services/global.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  products: Product[];
  Filtros: filtros;
  parametros: parametros;
  promocion: string;

  constructor(private apiService: ApiService, public global: GlobalService) {

   }

    async productList(): Promise<Product[]> {
      //if (this.products == null || this.products.length === 0) {
        this.products = await this.apiService.getArticulos('/api/articulos');
      //}
      return this.products;
    }

    async productPref(): Promise<Product[]> {
        this.products = await this.apiService.getArticulos('/api/articulos/preferenciales');
        return this.products;
    }

    async productRel(codigo): Promise<Product[]> {
      this.products = await this.apiService.getArticulos('/api/articulo/' + codigo + '/alternativos');
      return this.products;
    }

    async producto(codigo): Promise<Product[]> {
        this.products = await this.apiService.getArticulos('/api/articulo/' + codigo);
        return this.products;
    }

    async dameFiltros(): Promise<filtros> {
      this.Filtros = await this.apiService.getFiltros('/api/filtros');
      console.log('Filtros: ' + this.Filtros);
      return this.Filtros;
    }

    async dameParametros(): Promise<parametros> {
      this.parametros = await this.apiService.getParametros('/api/interno/parametros');
       ('Parametros: ' + this.parametros);
      return this.parametros;
    }

}
