import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { isNull } from 'util';
import { Cliente } from '../models/cliente.model';
import { pendiente } from '../models/pendiente.model';
import { Carro } from './../models/carro.model';



@Injectable({
  providedIn: 'root'
})
export class accountService {

  cliente: Cliente[];
  pendientes: pendiente[];
  carros: Carro[];
  carro: Carro;

  constructor(private apiService: ApiService) {

   }

    async Cliente(): Promise<Cliente[]> {
        this.cliente = await this.apiService.getCliente('/api/cliente');
        console.log('datos del cliente: ' + this.cliente);
        return this.cliente;
    }

    async dameCarritos(): Promise<Carro[]> {
        this.carros = await this.apiService.getCarritos('/api/cart/finalizados');
        console.log('Carritos: ' + this.carros);
        return this.carros;
    }

    async dameCarrito(carro): Promise<Carro[]> {
        this.carros = await this.apiService.getCarritos('/api/cart/finalizado?documento=' + carro + '&tipo=P');
        console.log('Carritos: ' + this.carros);
        return this.carros;
    }

    async damePendiente(): Promise<pendiente[]> {
        this.pendientes = await this.apiService.getPendiente('/api/contabilidad/pendientes');
        console.log('Pendiente: ' + this.pendientes);
        return this.pendientes;
    }

    /*async addcart(codigo): Promise<Product[]> {
        this.products = await this.apiService.getArticulos('/api/articulo/' + codigo);
        console.log('Producto: ' + this.products);
        return this.products;
    }

    async delcart(codigo): Promise<Product[]> {
        this.products = await this.apiService.getArticulos('/api/articulo/' + codigo);
        console.log('Producto: ' + this.products);
        return this.products;
    }*/
}
